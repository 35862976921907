<!doctype html>
<html lang="en">

<head>
  <!-- Required meta tags -->
  <meta charset="utf-8">
  <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">

  <!-- Bootstrap CSS -->
  <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css"
    integrity="sha384-9aIt2nRpC12Uk9gS9baDl411NQApFmC26EwAOH8WgZl5MYYxFfc+NcPb1dKGj7Sk" crossorigin="anonymous">


</head>

<body>
  <app-navbar></app-navbar>
  <div class="text-center" style="margin-top: 100px;">
    <h6>Empty Cart!!!</h6>
    <img src="../../../assets/pic/empty-cart.png" class="img-fluid" style="width: 500px;" alt="...">
  </div>

</body>

</html>